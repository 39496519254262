import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';
// import { withPrefix } from 'gatsby'
// import Vimeo from '@u-wave/react-vimeo';
import Frame1 from '../_images/profiles/frontline-2.jpg';
import Frame2 from '../_images/profiles/frontline-3.jpg';
import socialBanner from '../_images/profiles/frontline-social.jpg';
Modal.setAppElement('#___gatsby')
export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      videoModalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.videoTeaser = React.createRef();
  }
  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  openVideoModal() {
    this.setState({videoModalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Watch Video'
    });
  }
  closeVideoModal() {
    this.setState({videoModalIsOpen: false});
  }
  playVideo = () => {
    this.videoTeaser.current.play();
  }
  pauseVideo = () => {
    this.videoTeaser.current.pause();
  }
  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }
  render() {
    var pageTitle = 'Titans of the Front Line';
    var pageDesc = 'Titan nurses are among the brave and dedicated health care professionals on the front lines in the fight to save lives.';
    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />
          <meta name="description" content={pageDesc} />
          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />
          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />
          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
          {/* <meta property="og:video" content={ this.props.location.origin + withPrefix('/_video/teaser-mir.mp4') } />
          <meta property="og:video:secure_url" content={ this.props.location.origin + withPrefix('/_video/teaser-mir.mp4') } />
          <meta property="og:video:type" content="video/mp4" />
          <meta property="og:video:width" content="1920" />
          <meta property="og:video:height" content="1080" />
          <meta property="og:image" content={ this.props.location.origin + withPrefix('/_video/teaser-mir.jpg') } />
          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link> */}
        </Helmet>
        <section className="landing-wrap frontline" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              <div className="row">
                <h1>{pageTitle}</h1>
                {/* <div className="col-3">  
                  <blockquote>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>“</title><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"/></svg>
                    <p>“I don’t think there is any such thing as a throwaway human being. I know how easy it is to go down that wrong path. Higher education is the key to changing the narrative.”</p>
                  </blockquote>
                  <div className="clear"></div>
                </div> */}
                <div className="col-12">

                  <p>Titan nurses are among the brave and dedicated health care professionals on the front lines in the fight to save lives.</p>
                  
                  <p>Some are graduates of Cal State Fullerton’s School of Nursing, where they gained hands-on experience at the campus’ Nursing Simulation Center and in a variety of clinical settings across Southern California.</p>
                  
                  <p>Others are faculty members, who as practicing nurses share the latest learnings and their everyday realities with students.</p>
                  
                  <p>And Titan nursing students are eagerly volunteering at the Orange County Health Agency’s Operations Center, assisting with health screenings, research and personal protective equipment distribution.</p>

                  <p>For more than 40 years, CSUF’s nationally ranked nursing programs, steeped in cutting-edge research, experiential learning and community outreach, have been preparing future generations of nurses to take on any challenge — from the common cold to emerging viruses — with the strength and compassion of a Titan.</p>

                  <h2>Be the difference.</h2>
                  <p><strong>Help expand Cal State Fullerton’s interactive health care training for nurses and other health care students today.</strong></p>
                  <ul className="actions">
                    <li><a href="https://give.fullerton.edu/80561" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    {/* <li><a href="https://campaign.fullerton.edu/hhd/interactive-healthcare-training" className="buttonBlue outline" onClick={this.logger}>Learn More</a></li> */}
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>
                  <DOD college="hhd" layout="2" />
                </div>
              </div>
            </div>
          </div>
          <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Bryan Ruef demonstrates his software to first responders." />
          <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="A close up of 10-8 Systems software." />
          {/* <div className="video-wrap animated fadeIn" onMouseEnter={this.playVideo} onMouseLeave={this.pauseVideo}>
            <button onClick={this.openVideoModal} id="playVideo">
              <div></div>
              <div></div>
              <div></div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Watch Video</title><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
            </button>
            <video ref={this.videoTeaser} id="heroVideo" loop muted="muted" poster={ withPrefix('/_video/teaser-mir.jpg') } title="Intro Video">
              <source src={ withPrefix('/_video/teaser-mir.webm') } type="video/webm; codecs=vp9,vorbis" />
              <source src={ withPrefix('/_video/teaser-mir.mp4') } type="video/mp4" />
              <track kind="descriptions"  src={ withPrefix('/_video/teaser-mir.html') } />
            </video>
          </div> */}
        </section>
        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="frontline" college="the College of Health and Human Development" />
          </div>
        </Modal>
        {/* <Modal
          closeTimeoutMS={400}
          isOpen={this.state.videoModalIsOpen}
          onRequestClose={this.closeVideoModal}
          contentLabel="Watch Video"
          className="videoModal"
          overlayClassName="videoOverlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeVideoModal} id="closeButton"></button>
          <div className="wrap-video">
            <Vimeo
              video="393991778"
              responsive
              showTitle="false"
              showByline="false"
              showPortrait="false"
              autoplay
              onEnd={this.closeVideoModal}
            />
          </div>
        </Modal> */}
      </Layout>
      </React.Fragment>
    );
  }
}
